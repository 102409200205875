import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  IconButton, 
  Button, 
  TableContainer, 
  Table, 
  TableBody, 
  TableRow, 
  TableCell, 
  FormControl,
  FormHelperText,
  OutlinedInput,
  Collapse,
  Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const CustomersFormDialog = ({ modalState, handleModalState, selectedClient }) => {

  const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:${process.env.REACT_APP_DOMAIN_BACKEND_PORT}/api`;
  const [formData, setFormData] = useState({
    phone_no: '',
    client_code: '',
    client_name: '',
    email: '',
  });

  const [formErrors, setFormErrors] = useState({
    phone_no: '',
    client_code: '',
    client_name: '',
    email: '',
  });

  const [alertObj, setAlertObj] = useState({
    "active": false,
    "msg": "",
    "type": ""
  });

  const handleAlertClose = () => {
    setAlertObj({
      "active": false,
      "type": "",
      "msg": ""
    });
  };

  const formGroupStyle = {
    marginBottom: '15px',
  };

  const handlePopupClose = () => {
        handleModalState({"isOpen":false, "popupType": modalState.popupType});
        
        setFormErrors({
          phone_no: '',
          client_code: '',
          client_name: '',
          email: '',
        });
      };

  useEffect(() => {
    // If selectedClient is provided, update formData with its values
    if (selectedClient) {
      setFormData(selectedClient);
    } else {
      // If no selectedClient is provided (i.e., adding new client), reset formData
      setFormData({
        phone_no: '',
        client_code: '',
        client_name: '',
        email: '',
      });
    }
  }, [selectedClient]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAdd = () => {
    const newFormErrors = {};
    let hasErrors = false;
    let phonePattern = /^\d+$/;
    let eamilPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;


    if (formData.phone_no === '') {
      newFormErrors.phone_no = 'please enter a phone number';
      hasErrors = true;
    }
    else if (! phonePattern.test(formData.phone_no)){
      newFormErrors.phone_no = 'please enter a valid phone number';
      hasErrors = true;
    }

    if (formData.client_code.trim() === '') {
      newFormErrors.client_code = 'please enter a client code';
      hasErrors = true;
    }
    if (formData.client_name.trim() === '') {
      newFormErrors.client_name = 'please enter a name';
      hasErrors = true;
    }
    
    if (formData.email.trim() !== '' && ! eamilPattern.test(formData.email.trim())) {
      newFormErrors.email = 'please enter an valid email';
      hasErrors = true;
    }


    if (hasErrors) {
      setFormErrors(newFormErrors);
      return;
    }

    // API endpoint
    const apiUrl = URL + '/clients/';

    // API call
    axios.post(apiUrl, formData)
      .then(response => {
        console.log('API Response:', response.data);
        // Handle success, e.g., show a success message
        // Optionally, you can also update your UI or fetch new data
        setAlertObj({
          "active": true,
          "type": "success",
          "msg": "client created successfully"
        });
        // handlePopupClose();
      })
      .catch(error => {
        console.error('API Error:', error.response.data);
        let responseData =  error.response.data;
        if ("phone_no" in responseData){
          newFormErrors.phone_no = responseData.phone_no;
        }
        
        if ("client_code" in responseData){
          newFormErrors.client_code = responseData.client_code;
        }
        
        if ("client_name" in responseData) {
          newFormErrors.client_name = responseData.client_name;
        }

        setFormErrors(newFormErrors);
        // setAlertObj({
        //   "active": true,
        //   "type": "error",
        //   "msg": responseData.client_code[0]
        // });
        // Handle error, e.g., show an error message
      });
  };

  const handleEdit = () => {

    const newFormErrors = {};
    let hasErrors = false;
    // let phonePattern = /^\d{10}$/;
    let phonePattern = /^\d+$/;
    let eamilPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (formData.phone_no === '') {
      newFormErrors.phone_no = 'please enter a phone number';
      hasErrors = true;
    }
    else if (! phonePattern.test(formData.phone_no)){
      newFormErrors.phone_no = 'please enter a valid phone number';
      hasErrors = true;
    }

    if (formData.client_code.trim() === '') {
      newFormErrors.client_code = 'please enter a client code';
      hasErrors = true;
    }
    if (formData.client_name.trim() === '') {
      newFormErrors.client_name = 'please enter a client name';
      hasErrors = true;
    }
    
    if (formData.email.trim() !== '' && ! eamilPattern.test(formData.email.trim())) {
      newFormErrors.email = 'please enter an valid email';
      hasErrors = true;
    }
    
    if (hasErrors) {
      setFormErrors(newFormErrors);
      return;
    }
    // Handle editing existing client
    // Use formData to send the updated client data
    console.log('Editing client:', formData);

    // API endpoint
    const apiUrl = URL + `/clients/${selectedClient.id}/`;

    // API call
    axios.put(apiUrl, formData)
      .then(response => {
        console.log('API Response:', response.data);
        // Handle success, e.g., show a success message
        // Optionally, you can also update your UI or fetch new data
        setAlertObj({
          "active": true,
          "type": "success",
          "msg": "client updated successfully"
        });
        // handlePopupClose();
      })
      .catch(error => {
        console.error('API Error:', error.response.data);
        let responseData =  error.response.data;
        console.log(responseData);

        if ("phone_no" in responseData){
          newFormErrors.phone_no = responseData.phone_no;
        }
        
        if ("client_code" in responseData){
          newFormErrors.client_code = responseData.client_code;
        }
        
        if ("client_name" in responseData) {
          newFormErrors.client_name = responseData.client_name;
        }

        setFormErrors(newFormErrors);
        
        // setAlertObj({
        //   "active": true,
        //   "type": "error",
        //   "msg": responseData.client_code[0]
        // });
        // Handle error, e.g., show an error message
      });
  };

  return (
    <Dialog open={modalState.isOpen}>
      <DialogTitle sx={{ m: 0, p: 2 }}>Client</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handlePopupClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <TableContainer>
          <Collapse in={alertObj.active}><Alert style={formGroupStyle} onClose={handleAlertClose} severity={alertObj.type}>{alertObj.msg}</Alert></Collapse>

          <FormControl>
            <Table style={{ borderCollapse: 'collapse' }}>
              <TableBody>
                <TableRow>
                  <TableCell style={{ border: '1px solid #ddd' }}>Phone</TableCell>
                  <TableCell style={{ border: '1px solid #ddd' }}>
                    <OutlinedInput
                      name="phone_no"
                      placeholder="Please enter phone no"
                      size="small"
                      value={formData.phone_no}
                      onChange={handleChange}
                      id="client-phone"
                    />
                    <FormHelperText error>{formErrors.phone_no}</FormHelperText>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: '1px solid #ddd' }}>Client Code</TableCell>
                  <TableCell style={{ border: '1px solid #ddd' }}>
                    <OutlinedInput
                      name="client_code"
                      placeholder="Please enter client code"
                      size="small"
                      value={formData.client_code}
                      onChange={handleChange}
                      id="client-code"
                    />
                    <FormHelperText error>{formErrors.client_code}</FormHelperText>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: '1px solid #ddd' }}>Name</TableCell>
                  <TableCell style={{ border: '1px solid #ddd' }}>
                    <OutlinedInput
                      name="client_name"
                      placeholder="Please enter client name"
                      size="small"
                      value={formData.client_name}
                      onChange={handleChange}
                      id="client-name"
                    />
                    <FormHelperText error>{formErrors.client_name}</FormHelperText>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: '1px solid #ddd' }}>Email</TableCell>
                  <TableCell style={{ border: '1px solid #ddd' }}>
                    <OutlinedInput
                      name="email"
                      placeholder="Please enter an email"
                      size="small"
                      value={formData.email}
                      onChange={handleChange}
                      id="client-email"
                    />
                    <FormHelperText error>{formErrors.email}</FormHelperText>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </FormControl>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        {selectedClient ? (
          <Button autoFocus onClick={handleEdit}>Edit</Button>
        ) : (
          <Button autoFocus onClick={handleAdd}>Add</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default CustomersFormDialog;
