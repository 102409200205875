import './App.css';
import {useState, useEffect} from 'react';
import LoginForm from "./components/LoginForm.js";
import Home from "./components/Home.js";
import UploadHistory from './components/UploadHistory';
import Dashboard from './components/Dashboard';
import CustomerHomePage from "./components/Customers/CustomersHome.js";
import AuthProvider from './hooks/AuthProvider';
import PrivateRoute from "./router/route";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer';
import BaseTemplate from './components/BaseTemplate';

function App() {
  const [state, setState] = useState('Active');
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);

  const onIdle = () => {
    setState('Idle');
  }

  const onActive = () => {
    setState('Active')
  }

  const onAction = () => {
    setCount(count + 1)
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: (60 * 60 * 1000),  // 5 minutes
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })  

  return (
    <>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<BaseTemplate userState={state} remaining={remaining} component={<Home/>} />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/upload-history" element={<BaseTemplate userState={state} remaining={remaining} component={<UploadHistory/>} />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/client-master" element={<BaseTemplate userState={state} remaining={remaining} component={<CustomerHomePage/>} />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/kwiqreply-dashboard" element={<BaseTemplate userState={state} remaining={remaining} component={<Dashboard/>} />} />
            </Route> 
            {/* Other routes  */}
          </Routes>
        </AuthProvider>
      </Router>

    </>
  );
}

export default App;
