import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import UsersTable from './UsersTable';
import Container from '@mui/material/Container';

const User = (props) => { 
  return (
    <div className="App">
      {/* <Header userState={props.userState}/> */}
      <Container maxWidth={false}>
        {/* <ButtonRow /> */}
      {/* <p>State: {props.userState} Time remaining: {props.remaining}</p> */}
        <h2 className="heading-title">User Management</h2>
        <UsersTable key={props.refreshKey} />
      </Container>

    </div>
  );
}
export default User;
