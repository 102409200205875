import React, { useState } from 'react';
import { AppBar, Typography, Tabs, Tab, Toolbar, Button, Modal, Box } from "@mui/material";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import IconButton from '@mui/material/IconButton';
import Logo from "./Logo.js";
import { useAuth } from "../hooks/AuthProvider";

const Header = ({ userName, userState }) => {
  const auth = useAuth();
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  let state = userState;

  if (state === "Idle"){
    auth.logOut();
  }

  const handleOpenLogoutModal = () => {
    setLogoutModalOpen(true);
  };

  const handleCloseLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  const handleLogout = () => {
    // Perform logout action here
    auth.logOut();
    handleCloseLogoutModal();
  };

  const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {"name": "User"};

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Logo />
          <Tabs sx={{ marginLeft: "auto" }} value={0} textColor="inherit">
            <Tab label={"Welcome, " + user.name} />
            <IconButton aria-label="delete" size="medium" onClick={handleOpenLogoutModal}>
              <PowerSettingsNewIcon fontSize="inherit" sx={{ marginLeft: "auto", color: "#FFFFFF" }}></PowerSettingsNewIcon>
            </IconButton>
          </Tabs>
        </Toolbar>
      </AppBar>
      <Modal
        open={logoutModalOpen}
        onClose={handleCloseLogoutModal}
        aria-labelledby="logout-modal-title"
        aria-describedby="logout-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxWidth: 400,
          width: '80%',
          textAlign: 'center',
        }}>
          <Typography variant="h6" id="logout-modal-title">
            Are you sure you want to logout?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
            <Button onClick={handleLogout} variant="contained" color="error">
              Logout
            </Button>
            <Button onClick={handleCloseLogoutModal} variant="contained">
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Header;
