import * as React from "react";
import { useState } from 'react';
import axios from 'axios';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import ClientLogUploadHistoryTable from './ClientLogUploadHistoryTable';

const SyncKRClientLog = (props) => {
  const [value, setValue] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadError, setUploadError] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState('');
  const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:${process.env.REACT_APP_DOMAIN_BACKEND_PORT}/api`;

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setUploadError('');
    setUploadSuccess('');
  }

  const handleFileUpload = async () => {
    if (selectedFile) {
      if (selectedFile.size > 10 * 1024 * 1024) { // 10 MB
        setUploadError('File size exceeds the 10MB limit.');
        return;
      }

      let user_data = JSON.parse(localStorage.getItem("user"));
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('user_id', user_data.id);

      try {
        const postURL = `${URL}/kwiqreply/upload-logs/`;
        const response = await axios.post(postURL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        console.log('File uploaded:', response.data);
        setUploadSuccess('File uploaded successfully.');
        // Handle success, e.g., show a success message or refresh the table
      } catch (error) {
        console.error('Error uploading file:', error);
        if (error.response && error.response.status === 400) {
          setUploadError(error.response.data.message);
        } else {
          setUploadError('Error uploading file. Please try again.');
        }
      }
    }
  }

  return (
    <div className="App">
      {/* <Header userState={props.userState}/> */}
      <Container maxWidth={false}>
        {/* <ButtonRow /> */}
      {/* <p>State: {props.userState} Time remaining: {props.remaining}</p> */}
        <h2 className="heading-title">Sync Client Logs</h2>
        {uploadError && <Alert severity="error" sx={{ marginBottom: '20px' }}>{uploadError}</Alert>}
        {uploadSuccess && <Alert severity="success" sx={{ marginBottom: '20px' }}>{uploadSuccess}</Alert>}
        <Box 
          className="file-upload-wizard" 
          sx={{ 
            width: '100%', 
            marginBottom: '20px', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between', 
            padding: '10px', 
            border: '1px solid #ccc', 
            borderRadius: '5px', 
            backgroundColor: '#f9f9f9' 
          }}
        >
          <TextField
            type="file"
            onChange={handleFileChange}
            sx={{ flexGrow: 1, marginRight: '10px' }}
            inputProps={{ accept: ".csv" }}
          />
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleFileUpload}
            sx={{ whiteSpace: 'nowrap' }}
          >
            Upload
          </Button>
        </Box>
        <ClientLogUploadHistoryTable key={props.refreshKey} dateInput={value} />
      </Container>
    </div>
  );
}

export default SyncKRClientLog;
