import React  from 'react';
// import imgUrl from '../assets/images/Care_New_Logo.png' ;
import imgUrl from '../assets/images/logo2.png' ;

const logoStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  marginBottom: '10px'
};

const imgStyle = {
  width: '50px',
  height: '50px',
};

export default function Logo() {
  return (
    <div style={logoStyle}>
      <img src={imgUrl} style={imgStyle} alt="Company Logo" />
      <div>
      {/* <h4>CARE<br />Portfolio Managers</h4> */}
        <h4>BizCollab360 Tech</h4>
      </div>
    </div>
  );
}
