// Footer.js

import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <p className="text-muted text-center">Copyright &copy; {new Date().getFullYear()} BizCollab360 Tech  Private Limited. </p>
      </div>
    </footer>
  );
};

export default Footer;
