import React, { useState } from 'react'
import Container from '@mui/material/Container';
import SearchInputButton from "./SearchInputButton.js";
// import TableComponent from "./TableComponent.js";
import DatePickerAndButtons from "./DatePickerAndButtons.js";
import DailyTableComponent from './DailyTableComponent.js';
import MonthlyTablyComponent from './MonthlyTableComponent.js'

export default function FileUploadData({refreshKey}) {
  const [searchValue, setSearchValue] = useState("");
  const [holdings, setHoldings] = useState("daily");
  const [dateValue, setDateValue] = useState(null);
  
  const updateSearchValue = (value) => {
    setSearchValue(value);
  }

  const updateDateValue = (value) => {
    setDateValue(value);
  }

  const updateHoldingType = (value) =>{
    setHoldings(value);
  } 

  return (
    <div>
        <Container maxWidth={false}>
            <SearchInputButton updateSearchValue={updateSearchValue} holdings={holdings}/> 
            <DatePickerAndButtons updateHoldingType={updateHoldingType} updateDateValue={updateDateValue}/>
            { holdings === "daily" ? <DailyTableComponent searchInput={searchValue} key={refreshKey} dateInput={dateValue} /> : <MonthlyTablyComponent searchInput={searchValue} key={refreshKey} dateInput={dateValue} />} 
        </Container>
    </div>
  )
}
