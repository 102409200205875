import React  from 'react';
import imgUrl from '../assets/images/logo.webp' ;

const logoStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  marginBottom: '10px'
};

const imgStyle = {
  width: '50px',
  height: '50px',
};

export default function Logo() {
  return (
    <div style={logoStyle}>
      <img src={imgUrl} style={imgStyle} alt="Company Logo" />
      <div>
        <h4>BizCollab360<br />Tech Pvt Ltd</h4>
      </div>
    </div>
  );
}
