import * as React from "react";
import { useState, useEffect } from 'react';
import Header from './Header.js';
import SearchInputButton from "./SearchInputButton.js";
import DatePickerAndButtons from "./DatePickerAndButtons.js";
import UploadHistory from "./UploadHistory.js";
import CustomersHome from "./Customers/CustomersHome.js";
import Container from '@mui/material/Container';
import FileUploadData from './FileUploadData.js'

const Home = (props) => {

  return (
    <>  
        {/* <Header userState={props.userState} /> */}
        <Container maxWidth={false}>
        {/* <p>State: {props.userState} Time remaining: {props.remaining}</p> */}
          {/* <ButtonRow onUploadSuccess={handleUploadSuccess} /> */}
          <FileUploadData refreshKey={props.refreshKey} />
        </Container>
    </>
  );
}
export default Home;
