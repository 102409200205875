import React, { useState } from 'react';
import { IconButton, Input, InputAdornment, OutlinedInput } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const SearchInputButton = ({ updateSearchValue }) => {
  const [showInput, setShowInput] = useState(false);
  const [query, setQuery] = useState('');

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    if (event.target.value === '') {
      updateSearchValue(''); // Clear search filter
    }
  };

  const handleSearch = () => {
    updateSearchValue(query);
  };

  const handleSearchIconClick = () => {
    setShowInput(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className='search-row'>
      {showInput ? (
        <OutlinedInput 
          type="text"
          placeholder="Search with client code, mobile & name"
          value={query}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          style={{ width: '375px' }}
          autoFocus
          size="small"
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleSearch}>
                <SearchOutlinedIcon  color="primary" />
              </IconButton>
            </InputAdornment>
          }
        />
      ) : (
        <IconButton onClick={handleSearchIconClick}>
          <SearchOutlinedIcon color="primary" />
        </IconButton>
      )}
    </div>
  );
};

export default SearchInputButton;
