// The code set up is used for creating the authentication context in React using the Context API. 
// It creates an AuthContext using createContext() to manage the authentication state.

// The AuthProvider component is designed to wrap the application and provide the authentication context to its child components using the AuthContext.Provider

import { useContext, createContext, useState } from "react";
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem("site") || "");
    const navigate = useNavigate();

    const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:${process.env.REACT_APP_DOMAIN_BACKEND_PORT}/api`;
    const verifyOtpUrl = URL + `/auth/email/verify-otp/`;

    const loginAction = async (data) => {
        try {
          const response = await fetch(verifyOtpUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });
          const res = await response.json();

          if (response.status === 200) {
            setUser(res.user);
            setToken(res.key);
            localStorage.setItem("user", JSON.stringify(res.user));
            localStorage.setItem("site", res.key);
            navigate("/");
            return {'msg': 'valid otp', 'type': 'success'};
          }
          throw new Error(res.msg);
        } catch (err) {
          
          return {'msg': 'Invalid otp', 'type': 'error'}
        }
      };
    
      const logOut = () => {
        setUser(null);
        setToken("");
        localStorage.removeItem("site");
        localStorage.removeItem("user");
        navigate("/login");
      };
    
    return (
        <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
            {children}
        </AuthContext.Provider>
        );
}

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
}