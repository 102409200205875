// import React, { useState, useEffect, useRef } from 'react';
// import { Box, Typography, TextField, IconButton, Avatar, Stack } from '@mui/material';
// import SendIcon from '@mui/icons-material/Send';
// import PersonIcon from '@mui/icons-material/Person';
// import { useChatContext } from '../hooks/ChatProvider';
// import { blue } from '@mui/material/colors';
// import dayjs from "dayjs";
// import utc from "dayjs/plugin/utc";
// import timezone from "dayjs/plugin/timezone";

// // Initialize plugins
// dayjs.extend(utc);
// dayjs.extend(timezone);

// // Set default timezone
// dayjs.tz.setDefault("Asia/Kolkata");

// const ChatWindow = () => {
//     const { selectedUser, setChatData } = useChatContext();
//     const [data, setData] = useState([]);
//     const [inputValue, setInputValue] = useState("");
//     const socket = useRef(null);
//     const previousUser = useRef(null);

//     const adminSubscriberCode = "qsYVqG95L9udiUAdXICYASQ3tqXcjJX_9amBMGOe7hk"; // Replace with actual token logic

//     useEffect(() => {
//         // Close the existing WebSocket connection if switching users
//         if (socket.current) {
//             socket.current.close();
//         }

//         if (selectedUser) {
//             // Clear the previous chat data when switching users
//             setData([]);

//             // Initialize new WebSocket connection
//             socket.current = new WebSocket(`ws://localhost:8000/ws/chat/${selectedUser.topic}/?sub=${adminSubscriberCode}`);

//             socket.current.onopen = () => {
//                 console.log("WebSocket connection opened ...");
//             };

//             socket.current.onmessage = (event) => {
//                 if (event && event.data) {
//                     const message = JSON.parse(event.data);
//                     if (Array.isArray(message)) {
//                         // If the message is an array, assume it's the initial batch of messages
//                         setData(message);
//                     } else {
//                         // Otherwise, it's a new message
//                         setData((prevData) => [...prevData, message]);
//                     }
//                 }
//             };

//             socket.current.onerror = () => {
//                 console.error("WebSocket error");
//             };

//             socket.current.onclose = () => {
//                 console.log("WebSocket connection closed");
//             };

//             // Save the current user to ref
//             previousUser.current = selectedUser;
//         }

//         // Cleanup WebSocket connection on component unmount or when switching users
//         return () => {
//             if (socket.current) {
//                 socket.current.close();
//             }
//         };
//     }, [selectedUser]);

//     const handleOnClick = () => {
//         if (inputValue.trim() !== "" && socket.current && socket.current.readyState === WebSocket.OPEN) {
//             const newMessage = {
//                 user: adminSubscriberCode,
//                 message: inputValue,
//                 timestamp: new Date().toISOString(),
//             };
//             try {
//                 socket.current.send(JSON.stringify(newMessage));
//                 console.log("Message sent:", newMessage);
//                 setInputValue("");
//                 // Update local state immediately to reflect the message
//             } catch (error) {
//                 console.error("Error sending message:", error);
//             }
//         } else {
//             console.error("WebSocket not open or input value is empty.");
//         }
//     };

//     return (
//         <Box display="flex" flexDirection="column" height="100%">
//             <Box padding={2} borderBottom="1px solid #ccc" display="flex" alignItems="center">
//                 <Avatar><PersonIcon /></Avatar>
//                 <Typography variant="h6" component="div" marginLeft={2}>
//                     {selectedUser ? `Chat with +${selectedUser.phone}` : `Chat with User`}
//                 </Typography>
//             </Box>
//             <Box flex="1" overflow="auto" padding={4}>
//                 {data.map((message, index) => (
//                     <Box key={index} marginBottom={2} display="flex" flexDirection={message.user === adminSubscriberCode ? 'row-reverse' : 'row'}>
//                         <Box maxWidth="70%" bgcolor={message.user === adminSubscriberCode ? blue[500] : "#e0e0e0"} padding={1} borderRadius={3} position="relative" sx={{ wordBreak: 'break-word' }}>
//                             <Stack spacing={0.5} direction="column">
//                                 <Typography wrap sx={{ fontSize: 14, textAlign: "left" }} color={message.user === adminSubscriberCode ? "white" : "black"}>{message.message}</Typography>
//                                 <Stack direction="row-reverse">
//                                     <Typography wrap sx={{ fontSize: 10 }} color={message.user === adminSubscriberCode ? "white" : "black"}>{dayjs(message.timestamp).format('hh:mm A')}</Typography>
//                                 </Stack>
//                             </Stack>
//                         </Box>
//                     </Box>
//                 ))}
//             </Box>
//             <Box display="flex" padding={2} borderTop="1px solid #ccc">
//                 <TextField
//                     fullWidth
//                     placeholder="Type a message"
//                     variant="outlined"
//                     size="small"
//                     value={inputValue}
//                     onChange={(e) => setInputValue(e.target.value)}
//                     disabled={!selectedUser}
//                 />
//                 <IconButton color="primary" onClick={handleOnClick} disabled={!selectedUser || !inputValue.trim()}>
//                     <SendIcon />
//                 </IconButton>
//             </Box>
//         </Box>
//     );
// };

// export default ChatWindow;


import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, IconButton, Avatar, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import { useChatContext } from '../hooks/ChatProvider';
import { blue } from '@mui/material/colors';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Initialize plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Set default timezone
dayjs.tz.setDefault("Asia/Kolkata");

const ChatWindow = () => {
    const { selectedUser, setChatData } = useChatContext();
    const [data, setData] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const socket = useRef(null);

    const adminSubscriberCode = "qsYVqG95L9udiUAdXICYASQ3tqXcjJX_9amBMGOe7hk"; // Replace with actual token logic

    useEffect(() => {
        // Close the existing WebSocket connection if switching users
        if (socket.current) {
            socket.current.close();
        }

        if (selectedUser) {
            // Clear the previous chat data when switching users
            setData([]);

            // Initialize new WebSocket connection
            socket.current = new WebSocket(`ws://localhost:8000/ws/chat/${selectedUser.topic}/?sub=${adminSubscriberCode}`);

            socket.current.onopen = () => {
                console.log("WebSocket connection opened ...");
            };

            socket.current.onmessage = (event) => {
                if (event && event.data) {
                    const message = JSON.parse(event.data);
                    if (Array.isArray(message)) {
                        // If the message is an array, assume it's the initial batch of messages
                        setData(message);
                    } else {
                        // Otherwise, it's a new message
                        setData((prevData) => [...prevData, message]);
                    }
                }
            };

            socket.current.onerror = () => {
                console.error("WebSocket error");
            };

            socket.current.onclose = () => {
                console.log("WebSocket connection closed");
            };
        }

        // Cleanup WebSocket connection on component unmount or when switching users
        return () => {
            if (socket.current) {
                socket.current.close();
            }
        };
    }, [selectedUser]);

    const handleOnClick = () => {
        if (inputValue.trim() !== "" && socket.current && socket.current.readyState === WebSocket.OPEN) {
            const newMessage = {
                user: adminSubscriberCode,
                message: inputValue,
                timestamp: new Date().toISOString(),
            };
            try {
                socket.current.send(JSON.stringify(newMessage));
                console.log("Message sent:", newMessage);
                setInputValue("");
            } catch (error) {
                console.error("Error sending message:", error);
            }
        } else {
            console.error("WebSocket not open or input value is empty.");
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevents newline from being added
            handleOnClick();
        }
    };

    return (
        <Box display="flex" flexDirection="column" height="100%">
            <Box padding={2} borderBottom="1px solid #ccc" display="flex" alignItems="center">
                <Avatar><PersonIcon /></Avatar>
                <Typography variant="h6" component="div" marginLeft={2}>
                    {selectedUser ? `Chat with +${selectedUser.phone}` : `Chat with User`}
                </Typography>
            </Box>
            <Box flex="1" overflow="auto" padding={4}>
                {data.map((message, index) => (
                    <Box key={index} marginBottom={2} display="flex" flexDirection={message.user === adminSubscriberCode ? 'row-reverse' : 'row'}>
                        <Box maxWidth="70%" bgcolor={message.user === adminSubscriberCode ? blue[500] : "#e0e0e0"} padding={1} borderRadius={3} position="relative" sx={{ wordBreak: 'break-word' }}>
                            <Stack spacing={0.5} direction="column">
                                <Typography wrap sx={{ fontSize: 14, textAlign: "left" }} color={message.user === adminSubscriberCode ? "white" : "black"}>{message.message}</Typography>
                                <Stack direction="row-reverse">
                                    <Typography wrap sx={{ fontSize: 10 }} color={message.user === adminSubscriberCode ? "white" : "black"}>{dayjs(message.timestamp).format('hh:mm A')}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box display="flex" padding={2} borderTop="1px solid #ccc">
                <TextField
                    fullWidth
                    placeholder="Type a message"
                    variant="outlined"
                    size="small"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown} // Add this line
                    disabled={!selectedUser}
                />
                <IconButton color="primary" onClick={handleOnClick} disabled={!selectedUser || !inputValue.trim()}>
                    <SendIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default ChatWindow;
