import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import Header from './Header.js';
import Footer from "./Footer.js";
import ButtonRow from "./ButtonRow.js";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import UploadHistoryTable  from './UploadHistoryTable.js';
import Container from '@mui/material/Container';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import Box from '@mui/material/Box';


const UploadHistory = (props) => {  
  const [value, setValue] = useState(null);
  const [datetimeArray, setDateTimeArray] = useState(['day', 'month', 'year']);
  const [cleared, setCleared] = useState(false);

  const handleDateChange = (newDate) => {
    setValue(newDate);
  }

  return (
    <div className="App">
      {/* <Header userState={props.userState}/> */}
      <Container maxWidth={false}>
        {/* <ButtonRow /> */}
      {/* <p>State: {props.userState} Time remaining: {props.remaining}</p> */}
        <h2 className="heading-title">Upload History</h2>
        <div className='upload-date-picker-div'>
        <p>Latest Upload date</p>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <Box>
            <DemoItem label="">
              <DatePicker
                sx={{ width: 260 }}
                label=""
                defaultValue={value}
                onChange={(newValue) => handleDateChange(newValue)}
                disableFuture
                views={datetimeArray}
                slotProps={{
                  field: { clearable: true, onClear: () => setCleared(true) },
                }}
              />
            </DemoItem>
          </Box>
        </LocalizationProvider>
        </div>
        <UploadHistoryTable key={props.refreshKey} dateInput={value} />
      </Container>
    </div>
  );
}
export default UploadHistory;
