import React, { useState } from "react";
import Header from '../Header.js';
import Footer from "../Footer.js";
import ButtonRow from "../ButtonRow.js";
import { Container, Button, ButtonGroup } from '@mui/material';
import SearchInputButton from './SearchInputButton.js';
import CustomersTable from './CustomersTable.js';
import CustomersFormDialog from './CustomersFormDialog.js';

const CustomersHome = (props) => {
  const [searchValue, setSearchValue] = useState(""); 
  const [clientModal, setClientModal] = useState({
    "isOpen": false
  });
  const [selectedClient, setSelectedClient] = useState(null);

  const updateSearchValue = (value) => {
    setSearchValue(value);
  }

  const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:${process.env.REACT_APP_DOMAIN_BACKEND_PORT}/api`;
  
  const handlePopupOpen = (param, data) => {
    let popupType = param;
    // alert(JSON.stringify(data));
    setClientModal({"isOpen": true, "popupType": popupType, "data": data});
    setSelectedClient(data); // Set the selected client for editing
  }

  const handleModalChanges = (changes) => {
    setClientModal(changes);
  }

  return (
    <div>
      {/* <Header userState={props.userState}/> */}

      <Container maxWidth={false}>
        {/* <ButtonRow /> */}
      {/* <p>State: {props.userState} Time remaining: {props.remaining}</p> */}
        <div className="customers-home-add">
          <div className="customers-search-icon">
            <h2 className="heading-title">Clients</h2>
            <SearchInputButton updateSearchValue={updateSearchValue} />
          </div>
          <ButtonGroup variant="outlined" aria-label="Basic button group">
            <Button className={``} onClick={() => handlePopupOpen('Add', null)} variant="contained">Add</Button>
          </ButtonGroup>
        </div>
        <CustomersTable searchInput={searchValue} handleEditClick={(e, data) => handlePopupOpen('Edit', data)}/>
      </Container>
      
      {/* Dialog for adding/editing customer */}
      <CustomersFormDialog modalState={clientModal} handleModalState={handleModalChanges} selectedClient={selectedClient} refreshKey={props.refreshKey} />
      
    </div>
  );
}

export default CustomersHome;

