import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Chip from '@mui/material/Chip';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';

const columns = [
  { id: 'upload_date', label: 'Upload Date' },
  { id: 'file_name', label: 'File Name' },
  { id: 'uploaded_by', label: 'Uploaded By' },
  { id: 'Status', label: 'Status' },
  { id: 'Message', label: 'Message' },
];

export default function UploadHistoryTable({refreshKey, dateInput}) {
  const [rowCount, setRowcount] = useState(0);
  const [uploadHistoryData, setUploadHistoryData] = useState([]);
  const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:${process.env.REACT_APP_DOMAIN_BACKEND_PORT}/api`;
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20
  });
  

  useEffect(() => {
    let searchDateURL = '';
    if (dateInput)  {
      let dateString = dayjs(dateInput).format("YYYY-MM-DD");
        searchDateURL = `&search_date=${dateString}`;
    }

    const fetchData = async () => {
      try {
        
        const response = await fetch(URL + `/kwiqreply/upload-log/history/?limit=${controller.rowsPerPage}&offset=${controller.page * controller.rowsPerPage}` + searchDateURL);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        setUploadHistoryData(jsonData.results);
        setRowcount(jsonData.count);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };
    fetchData();
  }, [controller, refreshKey, dateInput]);
  
  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };


  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  style={{ minWidth: column.minWidth, border: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  <h3>{column.label}</h3>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            { uploadHistoryData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No match found
                </TableCell>
              </TableRow>
              ): 
              (
              uploadHistoryData.map((data, index) => (
                <TableRow key={index}>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{data.upload_date}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{data.file_name}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{data.user.first_name}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      {
                        data.status === "success" ? 
                        <Chip variant="outlined" color="primary" label="success" size="small" /> : 
                        <Chip variant="outlined" color="error" label="error" size="small" />
                      }
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 100, border: '1px solid rgba(224, 224, 224, 1)' }}>{data.message}</TableCell>
                </TableRow>
              ))
            )
          }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={rowCount}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
