import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemAvatar, ListItemText, Avatar, Divider, Typography, Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useChatContext } from '../hooks/ChatProvider';

const ChatList = () => {
  const [chats, setChats] = useState([]);

  // Function to truncate messages longer than 50 characters
  function truncateMessage(value) {
    return value.length > 50 ? value.substring(0, 50) + "..." : value;
  }

  const { selectedUser, setSelectedUser } = useChatContext();

  const handleClick = (user) => {
    
    setSelectedUser(user);
    console.log("User ", user);
  };

  useEffect(() => {
    const socket = new WebSocket('ws://localhost:8000/ws/chat/list/admin/qsYVqG95L9udiUAdXICYASQ3tqXcjJX_9amBMGOe7hk/');

    socket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      console.log(data);
      const topic = data.topic;
      const user = data.publisher;
      const lastMessage = data.last_msg;
      const {message, timestamp} = lastMessage;
      const { code, phone } = user;

      setChats((prevChats) => {
        const existingChatIndex = prevChats.findIndex(chat => chat.topic === topic);
        if (existingChatIndex !== -1) {
          const updatedChats = [...prevChats];
          updatedChats[existingChatIndex].message = message;
          // updatedChats[existingChatIndex].is_online = is_online;
          updatedChats[existingChatIndex].topic = topic;
          return updatedChats;
        } else {
          return [...prevChats, { id: prevChats.length + 1, topic, phone, message, topic }];
        }
      });

      console.log("Chats ", chats);
    };

    return () => socket.close();
  }, []);

  return (
    <List>
      {chats.length > 0 ? (
        chats.map((chat, index) => (
          <React.Fragment key={chat.code}>
            <ListItem button key={chat.id} onClick={() => handleClick(chat)}>
              <ListItemAvatar>
                <Avatar><PersonIcon /></Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`+${chat.phone}`}
                secondary={
                  <Typography component="span" variant="body2" style={{ color: '#d9d9d9' }}>
                    {truncateMessage(chat.message)}
                  </Typography>
                }
              />
            </ListItem>
            {index < chats.length - 1 && <Divider component="li" variant="middle" />}
          </React.Fragment>
        ))
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" py={5} color="textSecondary">
          <ChatBubbleOutlineIcon fontSize="large" />
          <Typography variant="h6" mt={2}>
            No Chats Available
          </Typography>
          <Typography variant="body2">
            Start a new conversation to see your chats here.
          </Typography>
        </Box>
      )}
    </List>
  );
};

export default ChatList;
