// ChatContext.js
import React, { createContext, useState, useContext } from 'react';

const ChatContext = createContext();

export const useChatContext = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  // const [selectedUser, setSelectedUser] = useState(null);
  const [chatData, setChatData] = useState([]);

  return (
    <ChatContext.Provider value={{ selectedUser, setSelectedUser, chatData, setChatData }}>
      {children}
    </ChatContext.Provider>
  );
};
