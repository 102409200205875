import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import Collapse from '@mui/material/Collapse';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HistoryIcon from '@mui/icons-material/History';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SyncIcon from '@mui/icons-material/Sync';
import PersonIcon from '@mui/icons-material/Person';
import ChatIcon from '@mui/icons-material/Chat';

import Home from './Home';
import UploadHistory from './UploadHistory';
import CustomerHomePage from './Customers/CustomersHome';
import SyncKRClientLog from './SyncKRClientLogs';
import Dashboard from './Dashboard';
import CustomerMsgDashboard from './CustomerMsgDashboard';
import User from './User';
import ChatDashboard from './ChatDashboard';
import Header from './Header';
import Footer from './Footer';
import { useAuth } from '../hooks/AuthProvider';

const drawerWidth = 270;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const sidebarData = {
  "1": { title: "Holdings", component: (props) => <Home {...props} />, icon: <AccountBalanceIcon /> },
  "2": { title: "Upload History", component: (props) => <UploadHistory {...props} />, icon: <HistoryIcon /> },
  "3": { title: "Client Master", component: (props) => <CustomerHomePage {...props} />, icon: <PeopleAltIcon /> },
  "4": { title: "Upload", component: null, icon: <FileUploadIcon /> },
  "5": { title: "Dashboard", component: (props) => <Dashboard {...props} />, icon: <LeaderboardIcon /> },
  "6": { title: "Message By Customer", component: (props) => <CustomerMsgDashboard {...props} />, icon: <QueryStatsIcon /> },
  "7": { title: "Sync Report Data", component: (props) => <SyncKRClientLog {...props} />, icon: <SyncIcon /> },
  "8": { title: "User", component: (props) => <User {...props} />, icon: <PersonIcon /> },
  "9": { title: "Chat", component: (props) => <ChatDashboard {...props} />, icon: <ChatIcon /> }
};

export default function MiniDrawer(props) {
  const [selectedComponentKey, setSelectedComponentKey] = useState("1"); // Keep track of the selected component key
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [uploadStatus, setUploadStatus] = useState(null);
  const [activeButton, setActiveButton] = useState('Daily');
  const [loading, setLoading] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);  // New state to manage refresh key

  const auth = useAuth();
  if (props.userState === "Idle") auth.logOut();

  const handleListItemClick = (key) => {
    setSelectedComponentKey(key);
  }

  const handleButtonClick = (buttonName) => setActiveButton(buttonName);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setAlertOpen(false);
    setFile(null);
  };

  const handleUploadClick = async () => {
    const fileInput = document.getElementById('file-upload');
    const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:${process.env.REACT_APP_DOMAIN_BACKEND_PORT}/api`;
    var fileUploadUrl = `${URL}/upload-csv/`;
    const user_data = JSON.parse(localStorage.getItem("user"));

    if (fileInput) {
      const inputFile = fileInput.files[0];
      let inputFileType = activeButton.toLowerCase();
      console.log(inputFileType);

      if (inputFileType === "monthly portfoilo"){
        inputFileType = "monthly_portfolio";
        fileUploadUrl = `${URL}/documents/upload/`;
      }

      if (inputFile) {
        const formData = new FormData();
        formData.append('file', inputFile);
        formData.append('file_type', inputFileType);
        formData.append('user_id', user_data.id);

        try {
          setLoading(true);
          const response = await fetch(fileUploadUrl, { method: 'POST', body: formData });

          if (response.ok) {
            setUploadStatus('success');
            setAlertOpen(true);
            setAlertMessage("File has been successfully loaded and the customer data will be updated.");
            if (props.onUploadSuccess) props.onUploadSuccess();
            setFile(null);
            fileInput.value = null;

            setRefreshKey(prevKey => prevKey + 1);  // Increment refresh key

          } else if (response.status === 413) {
            setUploadStatus('error');
            setAlertOpen(true);
            setAlertMessage("The selected file exceeds the size limit. Please choose a smaller file.");
            setFile(null); // Reset file state
            fileInput.value = null; // Clear file input
          } else {
            setUploadStatus('error');
            const data = await response.json();
            console.log("Error Data ", data);
            setAlertOpen(true);
            
            setAlertMessage(
              data?.non_field_errors?.length 
                ? data.non_field_errors.join(' ') 
                : 'Failed to upload file.'
            );
            setFile(null); // Reset file state
            fileInput.value = null; // Clear file input
          }
        } catch (error) {
          console.error('Error occurred during file upload:', error);
          setUploadStatus('error');
          setAlertOpen(true);
          setAlertMessage('Error occurred during file upload.');
          setFile(null);
          fileInput.value = null;
        } finally {
          setLoading(false);
        }
      } else {
        setAlertOpen(true);
        setAlertMessage("Please select a file to upload.");
      }
    } else {
      console.warn('File input element not found');
    }
  };

  const handleFileChange = (event) => setFile(event.target.files[0]);

  // Get the selected component dynamically using the selected key
  const SelectedComponent = sidebarData[selectedComponentKey].component;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <CssBaseline />
      <Header />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2, position: 'relative' }} id="customized-dialog-title">
          Upload File
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 0,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Collapse in={alertOpen}>
            {uploadStatus === 'success' ? (
              <Alert sx={{ mb: 2 }} severity="success">{alertMessage}</Alert>
            ) : (
              <Alert sx={{ mb: 2 }} severity="error">{alertMessage}</Alert>
            )}
          </Collapse>
          <div className="toggle-container">
            <ButtonGroup variant="outlined" aria-label="Basic button group">
              {['Daily', 'Monthly', 'Clients', 'Monthly Portfoilo'].map(buttonName => (
                <Button
                  key={buttonName}
                  variant={activeButton === buttonName ? 'contained' : 'outlined'}
                  onClick={() => handleButtonClick(buttonName)}
                >
                  {buttonName}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <div className='file-upload'>
            <Typography gutterBottom>
              Please select a file to upload:
            </Typography>
            <input type="file" onChange={handleFileChange} id="file-upload" />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleUploadClick} disabled={loading || !file}>
            {loading ? 'Uploading...' : 'Upload'}
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          <Toolbar sx={{ my: 2 }} />
          <Box sx={{ overflow: 'auto' }}>
            <List
              subheader={<ListSubheader component="div">Main</ListSubheader>}
            >
              {['1', '2', '3', '4'].map(key => (
                <ListItem key={key} disablePadding>
                  <ListItemButton onClick={() => key === '4' ? handleClickOpen() : handleListItemClick(key)}  >
                    <ListItemIcon>{sidebarData[key].icon}</ListItemIcon>
                    <ListItemText primary={sidebarData[key].title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
            <List subheader={<ListSubheader component="div">Reports</ListSubheader>}>
              {['5', '6', '7'].map(key => (
                <ListItem key={key} disablePadding>
                  <ListItemButton onClick={() => handleListItemClick(key)}>
                    <ListItemIcon>{sidebarData[key].icon}</ListItemIcon>
                    <ListItemText primary={sidebarData[key].title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
            <List
              subheader={<ListSubheader component="div">Settings</ListSubheader>}
            >
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleListItemClick('8')}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={sidebarData['8'].title} />
                </ListItemButton>
              </ListItem>
            </List> 
          </Box>
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
          <Toolbar sx={{ my: 1 }} />
          <Box sx={{ flexGrow: 1, mb: 2 }}>
            {SelectedComponent && <SelectedComponent refreshKey={refreshKey} />}
          </Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
