import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import  '../css/DatePickerAndButtons.css'; 
import 'dayjs/locale/en-gb';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';

const DatePickerAndButtons = ({updateHoldingType, updateDateValue}) => {

  const [value, setValue] = useState(dayjs(null));
  const [activeButton, setActiveButton] = useState('Daily'); // Initially set to 'Daily'
  const [datetimeArray, setDateTimeArray] = useState(['day', 'month', 'year']);
  const [cleared, setCleared] = useState(false);
    
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    if (buttonName === "Daily"){
      updateHoldingType('daily');
      setDateTimeArray(['day', 'month', 'year']);
    }
    else{
      updateHoldingType('monthly');
      setDateTimeArray(['month', 'year']);
    }
  };
    

  const handleDateChange = (newDate) => {
    setValue(newDate);
    updateDateValue(newDate);
  }

  return (
    <>
    <div className='parent-div'>
      <div className='date-picker-div'>
          <p>{activeButton === "Daily" ? "Latest Holdings Date" : "Latest Value Date"}</p>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <Box>
              <DemoItem label="">
                <DatePicker
                  sx={{ width: 260 }}
                  label={activeButton === "Daily" ? "DD/MM/YYYY" : "MMM/YYYY"}
                  defaultValue={null}
                  onChange={(newValue) => handleDateChange(newValue)}
                  disableFuture
                  views={datetimeArray}
                  slotProps={{
                    field: { clearable: true, onClear: () => setCleared(true) },
                  }}
                />
              </DemoItem>
            </Box>
          </LocalizationProvider>
      </div>
      <div className="toggle-container">
        <ButtonGroup variant="outlined" aria-label="Basic button group">
          <Button variant={activeButton === 'Daily' ? 'contained' : 'outlined'}
            onClick={() => handleButtonClick('Daily')} >
              Daily
          </Button>
          <Button variant={activeButton === 'Monthly' ? 'contained' : 'outlined'}
            onClick={() => handleButtonClick('Monthly')}>
              Monthly
          </Button>
        </ButtonGroup>
      </div>
    </div>
    </>
  );
  
};
export default DatePickerAndButtons;
