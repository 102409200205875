import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Collapse from '@mui/material/Collapse';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Link } from "react-router-dom";
import Alert from '@mui/material/Alert';
import '../css/ButtonRow.css';
import { useNavigate } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs({ updateSearchValue, onUploadSuccess }) {
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false); // State for dialog
  const [alertOpen, setAlertOpen] = useState(false); // State for alert
  const [alertMessage, setAlertMessage] = useState("");
  const [uploadStatus, setUploadStatus] = useState(null); // State for upload status
  const [searchValue, setSearchValue] = useState("");
  const [activeButton, setActiveButton] = useState('Daily'); // Initially set to 'Daily'
  const [loading, setLoading] = useState(false); // State for loading spinner
  const navigate = useNavigate();

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    buttonName === "Monthly" ? setFileType("monthly") : (buttonName === "Daily" ? setFileType("daily") : setFileType("clients"));
  };

  const handleClickOpen = () => {
    setOpen(true);
    setAlertOpen(false);
    setAlertMessage("");
    setUploadStatus(null);
  };

  const handleClose = () => {
    setOpen(false);
    setAlertOpen(false);
    setFile(null); // Reset file state to deactivate upload button
    if (uploadStatus === "success") navigate("/");
  };

  const [fileType, setFileType] = useState("daily");

  const handleUploadClick = async () => {
    const fileInput = document.getElementById('file-upload');
    const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:${process.env.REACT_APP_DOMAIN_BACKEND_PORT}/api`;

    const fileUploadUrl = URL + `/upload-csv/`;

    let user_data = JSON.parse(localStorage.getItem("user"));
    
    // Check if fileInput is not null
    if (fileInput) {
      const inputFile = fileInput.files[0];
      const inputFileType = fileType; 

      // Check if inputFile exists
      if (inputFile) {
        const formData = new FormData();
        formData.append('file', inputFile);
        formData.append('file_type', inputFileType);
        formData.append('user_id', user_data.id);

        try {
          setLoading(true); // Set loading to true while uploading
          // Make POST request here
          const response = await fetch(fileUploadUrl, {
            method: 'POST',
            body: formData,
          });

          if (response.ok) {
            setUploadStatus('success');
            setAlertOpen(true);
            setAlertMessage("File has been successfully loaded and the customer data will be updated.");
            if (onUploadSuccess) onUploadSuccess(); // Notify parent component
            setFile(null); // Reset file state
            fileInput.value = null; // Clear file input
            
          } else if (response.status === 413) {
            setUploadStatus('error');
            setAlertOpen(true);
            setAlertMessage("The selected file exceeds the size limit. Please choose a smaller file.");
            setFile(null); // Reset file state
            fileInput.value = null; // Clear file input
          } else {
            setUploadStatus('error');
            const data = await response.json();
            setAlertOpen(true);
            setAlertMessage(data?.non_field_errors || 'Failed to upload file.');
            setFile(null); // Reset file state
            fileInput.value = null; // Clear file input
          }
        } catch (error) {
          console.error('Error occurred during file upload:', error);
          setUploadStatus('error');
          setAlertOpen(true);
          setAlertMessage('Error occurred during file upload.');
          setFile(null); // Reset file state
          fileInput.value = null; // Clear file input
        } finally {
          setLoading(false); // Set loading back to false after upload completes
        }
      } else {
        setAlertOpen(true);
        setAlertMessage("Please select a file to upload.");
      }
    } else {
      console.warn('File input element not found');
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleAlertClose = (event) => {
    setAlertOpen(false);
    setOpen(false);
    setFile(null); // Reset file state to deactivate upload button
    navigate("/");
  }

  return (
    <div>
      <div className="button-row">
        <ButtonGroup variant="outlined" aria-label="Basic button group">
          <Button variant="outlined" component={Link} to="/">Holdings</Button>
          <Button variant="outlined" onClick={handleClickOpen}>Upload</Button>
          <Button variant="outlined" component={Link} to="/upload-history">Upload History</Button>
          <Button variant="outlined" component={Link} to="/client-master">Client Master</Button>
          <Button variant="outlined" component={Link} to="/kwiqreply-dashboard">Dashboard</Button>
        </ButtonGroup>
      </div>
      
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2, position: 'relative' }} id="customized-dialog-title">
          Upload File
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 0,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <DialogContent dividers>
          <Collapse in={alertOpen}>
            {
              uploadStatus === 'success' ? (
                <Alert sx={{ mb: 2 }} severity="success">{alertMessage}</Alert>
              ) : (
                <Alert sx={{ mb: 2 }} severity="error">{alertMessage}</Alert>
              )
            }
          </Collapse>
          <div className="toggle-container">
            <ButtonGroup variant="outlined" aria-label="Basic button group">
              <Button variant={activeButton === 'Daily' ? 'contained' : 'outlined'}
                onClick={() => handleButtonClick('Daily')} >
                Daily
              </Button>
              <Button variant={activeButton === 'Monthly' ? 'contained' : 'outlined'}
                onClick={() => handleButtonClick('Monthly')}>
                Monthly
              </Button>
              <Button variant={activeButton === 'Client' ? 'contained' : 'outlined'}
                onClick={() => handleButtonClick('Client')}>
                Clients
              </Button>
            </ButtonGroup>
          </div>
          <div className='file-upload'>
            <Typography gutterBottom>
              Please select a file to upload:
            </Typography>
            <input name="file_type" type="hidden" value={fileType} id="file_type" />
            <input type="file" onChange={handleFileChange} id="file-upload" />
          </div>
        </DialogContent>  
        <DialogActions>
          <Button autoFocus onClick={handleUploadClick} disabled={loading || !file}>
            {loading ? 'Uploading...' : 'Upload'}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
